import axios, { AxiosResponse } from "axios";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

interface Segment {
  id: string;
  prize: string;
  weight: number;
  color: string;
  textColor: string;
  isWin: boolean;
}

interface User {
  name: string;
  email: string;
  prize: string;
}

interface ApiResponse {
  success: boolean;
  message?: string;
}

export const PostUserSession = async (
  gameId: string,
  user: User,
  segment: Segment
): Promise<ApiResponse> => {
  const URL = REACT_APP_API_URL as string;

  try {
    const response: AxiosResponse<ApiResponse> = await axios.post(
      `${URL}/game/spin-the-wheel/user-session`,
      {
        spinTheWheelId: gameId,
        fullName: user.name,
        email: user.email,
        prize: segment.prize,
        isWinner: segment.isWin ? true : false,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};
