import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/store";
import {
  setSegments,
  setCollectUserInfo,
  setDivisionMode,
  setInAppParams,
} from "../store/slice/CustomizeSlice";
import { FaChevronDown } from "react-icons/fa";
import ToggleSwitch from "./ToggleSwitch";
import { Check } from "react-feather";
interface Segment {
  id: string;
  prize: string;
  weight: number;
  color: string;
  textColor: string;
  isWin: boolean;
  probabilityPercentage: number;
}

interface DivisionMode {
  name: string;
  value: "equal" | "custom";
}

interface InteractivityProps {}

const options = [
  { name: "Win", value: true },
  { name: "Lose", value: false },
];

const divisionModes: DivisionMode[] = [
  { name: "Equally Divide", value: "equal" },
  { name: "Custom Percentage", value: "custom" },
];

const Interactivity: React.FC<InteractivityProps> = ({}) => {
  const dispatch = useDispatch();
  const { segments, collectUserInfo, divisionMode, inAppParams } = useSelector(
    (state: RootState) => state.customize,
  );

  const handleGamificationToggle = () => {
    dispatch(setInAppParams(!inAppParams));
  };

  // Function to calculate the total probability
  const getTotalProbability = (segments: Segment[]): number => {
    return segments.reduce(
      (total: number, segment: Segment) =>
        total + segment.probabilityPercentage,
      0,
    );
  };

  const handleItemClick = (option: string, segmentId: string) => {
    const newSegments = segments.map((segment) => {
      if (segment.id === segmentId) {
        return {
          ...segment,
          isWin: option === "Win",
        };
      }
      return segment;
    });
    dispatch(setSegments(newSegments));
  };

  const handleUserInfo = () => {
    dispatch(setCollectUserInfo(!collectUserInfo));
  };

  const dropdownRef = useRef<HTMLDivElement>(null);
  const [openDropdownId, setOpenDropdownId] = useState<number | null>(null);

  const toggleDropdown = (id: number) => {
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setOpenDropdownId(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleProbabilityChange = (
    segmentId: string,
    newProbability: number,
  ) => {
    // Ensure that the input is a valid number before proceeding
    if (isNaN(newProbability)) {
      console.log(`Invalid input: ${newProbability}`);
      return;
    }

    // Calculate the total probability excluding the updated segment's current value
    const totalCurrent = getTotalProbability(segments);
    const currentSegment = segments.find((seg) => seg.id === segmentId);

    if (!currentSegment) return;

    const updatedTotal =
      totalCurrent - currentSegment.probabilityPercentage + newProbability;

    // Ensure the total does not exceed 100
    if (updatedTotal <= 100) {
      // Create a new array with updated segments
      const newSegments = segments.map((segment) => {
        if (segment.id === segmentId) {
          // Return a new object for the updated segment
          return {
            ...segment,
            probabilityPercentage: newProbability,
          };
        }
        return segment; // Return the unchanged segment
      });

      // console.log("Updating segments:", newSegments);

      // Dispatch the new segments array
      dispatch(setSegments(newSegments));
    } else {
      console.warn("Total probability exceeds 100%");
    }
  };

  const handleEqualDivision = () => {
    const totalSegments = segments.length;
    const newProbability = (100 / totalSegments).toFixed(0); // No decimals

    const newSegments = segments.map((segment) => ({
      ...segment,
      probabilityPercentage: Number(newProbability),
    }));

    dispatch(setSegments(newSegments));
  };

  useEffect(() => {
    if (divisionMode === "equal") {
      handleEqualDivision();
    }
  }, [divisionMode, segments.length]); // Recalculate when mode changes or segments update

  return (
    <div className="interactivity flex flex-col gap-y-2 pb-[5rem] xl:pb-5">
      <div className="flex w-full flex-col justify-between">
        <ToggleSwitch
          label="Collect only winners Data"
          checked={collectUserInfo}
          onChange={handleUserInfo}
          tooltip="We will collect both winners and losers details like name and email
            by default"
          tooltipId="collectUserInfo-tooltip"
        />
      </div>

      <div className="flex w-full flex-col justify-between">
        <ToggleSwitch
          label="Enable In-app Gamification"
          checked={inAppParams}
          onChange={handleGamificationToggle}
          tooltip="Enable gamification features in the app by passing a uid (required, e.g., uid=EyshdsYEWjsd) along with any additional parameters as needed in the url."
          tooltipId="inAppGamification-tooltip"
        />
      </div>

      <div className="flex flex-col text-left">
        <p className="mb-3 block text-base font-semibold">
          Select division mode for slice probability
        </p>
        <div className="mb-1 flex gap-4">
          {divisionModes.map((mode) => (
            <button
              key={mode.value}
              onClick={() => dispatch(setDivisionMode(mode.value))}
              className={`rounded-md px-4 py-2 ${
                divisionMode === mode.value
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200"
              }`}
            >
              {mode.name}
            </button>
          ))}
        </div>

        <p className="mb-2 mt-4 block text-base font-semibold">
          Select win/loss scenario for each slice and set probabilities
        </p>

        <div className="z-10 flex w-full flex-col justify-start divide-y divide-gray-100 rounded-lg p-0">
          <ul
            className="m-0 flex w-full flex-col items-start justify-start gap-y-2 p-0 py-2 text-sm"
            aria-labelledby="dropdownDefaultButton"
          >
            {segments.map((segment, index) => (
              <li
                className="flex w-full flex-col justify-start gap-y-4 rounded-lg"
                key={segment.id}
              >
                <div className="flex h-12 items-center justify-between gap-x-3">
                  <p className="w-full rounded-md bg-[#f5f5f6] p-3 text-start text-[15px]">
                    {segment.prize}
                  </p>
                  <div className="relative flex w-auto items-center">
                    <input
                      type="number"
                      min="0"
                      max="100"
                      value={segment.probabilityPercentage}
                      onChange={
                        divisionMode === "custom"
                          ? (e) => {
                              const newProbability =
                                e.target.value === ""
                                  ? 0
                                  : Math.floor(Number(e.target.value));
                              handleProbabilityChange(
                                segment.id,
                                newProbability,
                              );
                            }
                          : undefined // Allow only integers in custom mode
                      }
                      className={`mr-2 h-10 w-20 rounded-md border border-gray-300 p-2 text-sm ${divisionMode === "equal" ? "cursor-not-allowed opacity-50" : ""}`}
                      disabled={divisionMode === "equal"} // Disable input in equal mode
                      // Use camelCase for the style properties
                      style={{
                        MozAppearance: "textfield", // Updated to camelCase
                        WebkitAppearance: "none", // Updated to camelCase
                      }}
                    />

                    <button
                      onClick={() => toggleDropdown(index)}
                      className="flex h-10 w-20 items-center justify-between rounded-md border-2 border-transparent bg-[#f5f5f6] px-3 text-left text-sm shadow-sm outline-none hover:border-blue-500 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                      <span className="truncate text-[15px]">
                        {segment.isWin ? "Win" : "Lose"}
                      </span>
                      <FaChevronDown
                        className={`h-3 transition-transform ${openDropdownId === index ? "rotate-180" : ""}`}
                      />
                    </button>

                    {openDropdownId === index && (
                      <div
                        ref={dropdownRef}
                        className={`absolute right-0 z-50 mt-2 h-auto w-20 overflow-auto rounded-md border border-gray-300 bg-white text-[15px] shadow-lg ${
                          segments.length - 1 === index
                            ? "bottom-full"
                            : "-top-2"
                        }`}
                      >
                        {options.map((option) => (
                          <div
                            key={option.name}
                            className="flex cursor-pointer items-center justify-between px-4 py-2 hover:bg-stone-100"
                            onClick={() => {
                              handleItemClick(option.name, segment.id);
                              setOpenDropdownId(null); // Close the dropdown here
                            }}
                          >
                            {option.name}
                            {segment.isWin === option.value && (
                              <div className="ml-2 text-blue-500">
                                <Check size={16} />
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Interactivity;
