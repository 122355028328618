import { X } from "react-feather";
import { RootState } from "../store/store";
import { useSelector } from "react-redux";
import { setLoader, setDone } from "../store/slice/CustomizeSlice";
import toast from "react-hot-toast";
import { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";

interface PopUpProps {}

const PopUp: React.FC<PopUpProps> = () => {
  const { GameID, isdone } = useSelector((state: RootState) => state.customize);
  const dispatch = useDispatch();
  const isLinked = sessionStorage.getItem("linked") || null;

  const navigateToPlayWithQueryParam = () => {
    toast.success("Redirecting to game");
    setTimeout(() => {
      let url = `/play?gameId=${encodeURIComponent(GameID)}`;
      window.open(url, "_blank");
    }, 1000);
  };

  console.log(GameID);

  return (
    <div className="fixed inset-0 z-40 flex min-h-full min-w-full flex-col items-center overflow-y-auto overflow-x-hidden transition">
      <div className="z-10 flex h-screen w-screen bg-transparent backdrop-blur-sm"></div>
      <div className="PopShadow absolute left-1/2 top-1/2 z-20 flex w-72 -translate-x-1/2 -translate-y-1/2 transform flex-col items-center justify-center rounded-lg bg-[white] p-10 sm:w-96">
        <p className="my-2 text-center text-lg font-bold">
          Your personalized spin the wheel game is ready for you !
        </p>
        <p className="mb-8 text-center"> Click 'Get Link' to access it.</p>

        <button
          onClick={() => {
            window.location.href = `${process.env.REACT_APP_WEBSITE_URL}/login?from=spin-the-wheel&gameId=${encodeURIComponent(
              GameID,
            )}`;
          }}
          className="rounded-full bg-blue-500 px-6 py-2 font-medium text-white hover:bg-blue-600"
        >
          {" "}
          Get Link
        </button>

        <button
          onClick={() => {
            if (isLinked) {
              window.location.href = "/";
            }
            setLoader(true);
            dispatch(setDone(!isdone));
            setTimeout(() => {
              dispatch(setLoader(false));
            }, 300);
          }}
          className="absolute right-2 top-2 h-6 cursor-pointer text-black"
        >
          {" "}
          <X />
        </button>
      </div>
      <Toaster />
    </div>
  );
};

export default PopUp;
