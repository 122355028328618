import { Routes, Route } from "react-router";
import "./App.css";
import GameCustomize from "./pages/CustomizeSpinPage";
import Play from "./pages/PlayPage";
import PageNotFound from "./pages/PageNotFoundPage";
import Home from "./pages/Home";

function App() {
  return (
    <div className="">
      <div className="m-0 flex flex-col items-center justify-center p-0">
        <Routes>
          <Route path="/" element={<GameCustomize />} />
          <Route path="/play" element={<Home />} />
          <Route path="/game" element={<Play />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
