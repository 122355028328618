import { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/store";
import { IoIosColorPalette } from "react-icons/io";
import { BiFontColor } from "react-icons/bi";
import { setSegments } from "../store/slice/CustomizeSlice";

interface ColorInputProps {
  Index: number;
  id: string;
  defaultColor: string;
  isText: boolean;
}

const ColorInput: React.FC<ColorInputProps> = ({
  id,
  defaultColor,
  Index,
  isText,
}) => {
  const ColorInputRef = useRef<HTMLInputElement>(null);
  const { segments } = useSelector((state: RootState) => state.customize);
  const dispatch = useDispatch();
  const handleClick = () => {
    if (ColorInputRef.current) {
      ColorInputRef.current.click();
    }
  };

  const handleColorChange = (value: string) => {
    const updatedSegments = [...segments];
    const segmentToUpdate = { ...updatedSegments[Index] };

    if (isText) {
      segmentToUpdate.textColor = value;
    } else {
      segmentToUpdate.color = value;
    }

    updatedSegments[Index] = segmentToUpdate;

    dispatch(setSegments(updatedSegments));
  };

  return (
    <div className="relative">
      <button
        className={`flex h-10 w-10 flex-col items-center justify-center rounded-lg`}
        onClick={handleClick}
        style={{ backgroundColor: !isText ? defaultColor : "#d1d5db" }}
      >
        {isText ? (
          <BiFontColor style={{ color: segments[Index].textColor }} />
        ) : (
          <IoIosColorPalette style={{ color: "black" }} />
        )}
      </button>
      <input
        id={id}
        type="color"
        ref={ColorInputRef}
        defaultValue={defaultColor}
        className="absolut invisible"
        onChange={(e) => handleColorChange(e.target.value)}
      />
    </div>
  );
};

export default ColorInput;
