import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { Check } from "react-feather";
import { FaChevronDown } from "react-icons/fa";

const CustomOptions: React.FC<{
  options: any[];
  value: any;
  onChange: (value: string) => void;
}> = ({ options, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const { fontFamily } = useSelector((state: RootState) => state.customize);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: string) => {
    onChange(option);

    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };
  const selectedOption = options.find((option) => option.value === value);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-auto" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className="flex w-56 items-center justify-between rounded-md border-2 border-transparent bg-[#f5f4f7] px-4 py-2 text-left text-base font-medium shadow-sm outline-none hover:border-blue-500 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        <span className="truncate">
          {selectedOption ? selectedOption.name : fontFamily}
        </span>
        <FaChevronDown
          className={`h-3 transition-transform ${isOpen ? "rotate-180" : ""}`}
        />
      </button>

      {isOpen && (
        <div className="absolute bottom-14 z-50 mt-2 h-40 w-full overflow-auto rounded-md border border-gray-300 bg-white shadow-lg">
          {options.map((option) => (
            <div
              key={option.value}
              onClick={() => handleOptionClick(option.value)}
              className="flex cursor-pointer items-center justify-between px-4 py-2 hover:bg-gray-100"
            >
              <span>{option.name}</span>
              {option.value === value && (
                <Check className="h-4 text-blue-800" />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomOptions;
