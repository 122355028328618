import { useEffect, useState } from "react";
import Favicon from "../assets/WorkPlay_FaviCon (3).svg";
import Specifications from "./Specifications";
import Design from "./Design";
import Interactivity from "./Interactivity";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";

interface CustomizationFormProps {
  selectedMenu: string;
  SetMenu: React.Dispatch<React.SetStateAction<string>>;
  setClose: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomizationForm: React.FC<CustomizationFormProps> = ({
  selectedMenu,
}) => {
  const { spinning } = useSelector((state: RootState) => state.customize);

  return (
    <div
      style={{ pointerEvents: spinning ? "none" : "auto" }}
      className="flex h-full flex-row justify-center overflow-y-auto rounded-md px-4 py-3 xl:px-8"
    >
      <div className="w-full">
        {selectedMenu === "Design" && <Design />}
        {selectedMenu === "Interactivity" && <Interactivity />}
        {selectedMenu === "Specification" && <Specifications />}
      </div>
    </div>
  );
};

export default CustomizationForm;
