import axios from "axios";
import { AppThunk } from "../store/store";
import {
  setSegments,
  setFontSize,
  setFontFamily,
  setBackgroundColor,
  setCollectUserInfo,
  setTitle,
  setDescription,
  setTextColor,
  setSecondaryColor,
  setInstruction,
  setInstructions,
  setStart,
  setEnd,
  setBackgroundImg,
  setStyle,
  setLogo,
  setLogoUrl,
  setLoader,
  setCampaignID,
  setFaviconUrl,
  setInAppParams,
} from "../store/slice/CustomizeSlice";

const { REACT_APP_S3_URL, REACT_APP_API_URL } = process.env;

export const fetchData =
  (
    gameId: string,
  ): AppThunk<Promise<{ status: number; message: string } | void>> =>
  async (dispatch) => {
    try {
      sessionStorage.removeItem("updateDone");
      dispatch(setLoader(true));

      const response = await axios.get(
        `${REACT_APP_API_URL}/game/spin-the-wheel/${gameId}`,
        {
          httpsAgent: false,
        },
      );
      // console.log("Fetched Response:", response.data.data);

      // Handle successful response
      if (response.status === 200) {
        // Check if the event has started
        if (response.data.message === "Event yet to start") {
          return {
            status: response.status,
            message: response.data.message,
          };
        }
        const responseData = response.data.data;
        const logo = responseData.logo || {};
        // Dispatch actions with the data
        dispatch(setSegments(responseData.segments));
        dispatch(setFontSize(responseData.background.textSize));
        dispatch(setFontFamily(responseData.background.fontFamily));
        dispatch(setBackgroundColor(responseData.background.hexCode));
        dispatch(setLogoUrl(responseData.logo?.websiteUrl || ""));
        dispatch(setFaviconUrl(responseData.favicon));
        dispatch(setCollectUserInfo(responseData.winnersOnly));
        dispatch(setTitle(responseData.title));
        dispatch(setDescription(responseData.description));
        dispatch(setTextColor(responseData.textColor));
        dispatch(setInAppParams(responseData.inAppParams));
        dispatch(setSecondaryColor(responseData.secondaryColor));
        dispatch(setInstruction(responseData.displayInstructions));
        dispatch(setStart(responseData.campaign.startDate));
        dispatch(setCampaignID(responseData.campaign._id));
        dispatch(setEnd(responseData.campaign.endDate));
        dispatch(
          setInstructions(
            responseData?.instructions.map((text: string, index: number) => ({
              id: index + 1,
              text,
            })),
          ),
        );

        // Handle background image or color
        if (response.data.data.background.image) {
          dispatch(setBackgroundImg(response.data.data.background.image));
          dispatch(
            setStyle({
              backgroundImage: `url(${REACT_APP_S3_URL}/spin-the-wheel/background/${response.data.data.background.image})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }),
          );
        }

        if (!response.data.data.background.image) {
          dispatch(setBackgroundImg(""));
          dispatch(
            setStyle({
              backgroundColor: response.data.data.background.hexCode,
            }),
          );
        }

        const logoImage = logo.image || ""; // Use empty string if logo.image is undefined or an empty string
        dispatch(setLogo(logoImage));

        return {
          status: response.status,
          message: response.data.message,
        };
      }

      // If we reach here, something went wrong with the response status
      console.error("Unexpected status:", response.status);
      return { status: response.status, message: "Unexpected error occurred." };
    } catch (error) {
      // Check if error response exists
      if (axios.isAxiosError(error) && error.response) {
        console.error("Error fetching data:", error.response.data);
        const { status, data } = error.response;
        if (status === 400 && data.message === "Event link expired") {
          return { status, message: data.message }; // Return the specific error message
        }
        return { status, message: "Unexpected error occurred." };
      } else {
        console.error("Error fetching data:", error);
        return { status: 500, message: "Error fetching data" }; // Fallback status and message
      }
    } finally {
      dispatch(setLoader(false)); // Set loader to false after fetching data
    }
  };
