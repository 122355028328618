interface Menu {
  name: string;
  icon: any;
}

interface MobileToolBarProps {
  menu: Menu[];
  selectedMenu: string;
  close: boolean;
  setClose: (value: boolean) => void;
  setSelectedMenu: (menu: string) => void;
  setContent: (value: string) => void;
}

const MobileToolBar: React.FC<MobileToolBarProps> = ({
  menu,
  selectedMenu,
  close,
  setClose,
  setSelectedMenu,
  setContent,
}) => {
  const handleClick = (menu: string) => {
    setClose(false);
    setSelectedMenu(menu);
    setContent("customize");
  };

  return (
    <div className="absolute bottom-0 z-40 flex w-screen flex-row items-center justify-center gap-y-4 border-t-2 border-secondary bg-white p-1 xl:hidden">
      <div
        className={`flex h-[4rem] w-full flex-row items-center justify-between px-10 shadow-sm`}
      >
        {menu.map((item, index) => (
          <div
            key={item.name}
            data-tooltip-id={item.name}
            data-tooltip-content={item.name}
            onClick={() => handleClick(item.name)}
            className={`flex flex-col items-center py-1 ${selectedMenu === item.name ? "text-blue-500" : "text-black"}`}
          >
            <item.icon size={20} />
            <p
              className={`cursor-pointer text-sm ${selectedMenu === item.name ? "text-blue-500" : "text-black"} ${
                close ? "text-white" : ""
              } text-[#202022]`}
            >
              {item.name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MobileToolBar;
