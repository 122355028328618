import axios from "axios";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

interface LinkGameToUserProps {
  token: string;
  gameId: string;
}

export const linkGameToUser = async ({
  token,
  gameId,
}: LinkGameToUserProps): Promise<void> => {
  if (token && gameId) {
    const dataToUpdate = {
      key: gameId,
    };
    try {
      const linkResponse = await axios.patch(
        `${REACT_APP_API_URL}/game/spin-the-wheel/link-user/${gameId}`,
        dataToUpdate,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
    } catch (error: any) {
      console.log(error.response.data.message);
    }
  }
};
