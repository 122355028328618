import React from "react";

const { REACT_APP_S3_URL, REACT_APP_WEBSITE_URL } = process.env;

const BuiltWithWorkplay: React.FC = () => {
  return (
    <div className="absolute bottom-0 left-0 right-0 flex items-center justify-center bg-[#ffffffcc] py-2">
      <span className="text-[15px] text-gray-600">Built with </span>
      <img
        src={`${REACT_APP_S3_URL}/assets/workplay-logo.webp`}
        alt="workplay-logo"
        className="ml-2 h-3.5 cursor-pointer"
        onClick={() => window.open(`${REACT_APP_WEBSITE_URL}`, "_blank")}
      />
    </div>
  );
};

export default BuiltWithWorkplay;
