import axios from "axios";

export interface UserSessionParams {
  gameId: string;
  uid: string;
  extraParams: Record<string, any>;
}

export interface UserSessionResponse {
  prize: string;
}

export const fetchUserSession = async ({
  gameId,
  uid,
  extraParams,
}: UserSessionParams): Promise<UserSessionResponse | null> => {
  const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

  const requestBody = {
    spinTheWheelId: gameId,
    fullName: "default",
    email: "default",
    inAppParams: {
      uid: uid,
      ...extraParams,
    },
  };

  try {
    const response = await axios.post(
      `${REACT_APP_API_URL}/game/spin-the-wheel/user-session`,
      requestBody,
    );

    return response.data.data || null;
  } catch (error: any) {
    console.error("API error:", error.message);
    throw new Error(error.response?.data?.message || "An error occurred.");
  }
};
