import { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Switch } from "@headlessui/react";
import { uploadLogoImage } from "../api/uploadLogoAPI";
import ColorInput from "./ColorInput";
import { useDropzone } from "react-dropzone";
import { X, Plus, Trash } from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/store";
import {
  setSegments,
  setTitle,
  setDescription,
  setInstructions,
  setInstruction,
  setLogo,
  setLogoUrl,
  setFavIcon,
  setFaviconUrl,
} from "../store/slice/CustomizeSlice";
import { uploadFavicon } from "../api/uplooadFavicon";
import { BiDuplicate } from "react-icons/bi";

interface SpecificationsProps {}

const { REACT_APP_S3_URL } = process.env;

const Specifications: React.FC<SpecificationsProps> = ({}) => {
  const {
    segments,
    title,
    description,
    instructions,
    instruction,
    logo,
    LogoUrl,
    FavIcon,
    FaviconUrl,
  } = useSelector((state: RootState) => state.customize);
  const dispatch = useDispatch();
  const [isTitleFocused, setIsTitleFocused] = useState(false);
  const [specification, SetSpecification] = useState("Segments");
  const [isDescriptionFocused, setIsDescriptionFocused] = useState(false);
  const [focusedInstruction, setFocusedInstruction] = useState<number | null>(
    null,
  );
  const [focusedSegment, setFocusedSegment] = useState<number | null>(null);
  const [errors, setErrors] = useState<boolean[]>(segments.map(() => false));

  const [localTitle, setLocalTitle] = useState(title);

  const [localDescription, setLocalDescription] = useState(description);

  const [localInstructions, setLocalInstructions] = useState(instructions);
  // console.log("Current segments from Redux:", segments);
  useEffect(() => {
    setLocalInstructions(instructions);
  }, [instructions]);

  const handleInstructionBlur = (id: number, index: number) => {
    const instruction = localInstructions.find((instr) => instr.id === id);
    if (instruction && !instruction.text.trim()) {
      const defaultText = `Instruction line ${index + 1}`;
      setLocalInstructions((prevInstructions) =>
        prevInstructions.map((instr) =>
          instr.id === id ? { ...instr, text: defaultText } : instr,
        ),
      );
      const updatedInstruction = instructions.map((instruction, i) =>
        i === index ? { ...instruction, text: defaultText } : instruction,
      );
      dispatch(setInstructions(updatedInstruction));
    }
    setFocusedInstruction(null);
  };

  const handleSegmentBlur = () => {
    setFocusedSegment(null);
  };

  const handleSpecification = (menu: string) => {
    SetSpecification(menu);
  };

  const handleDisplayInstructionsToggle = () => {
    dispatch(setInstruction(!instruction));
    if (instructions.length < 1) {
      const newItem = {
        id: 1,
        text: `Instruction line ${instructions.length + 1}`,
      };
      const newInstructions = [...instructions];
      newInstructions.splice(instructions.length + 1, 0, newItem);
      dispatch(setInstructions(newInstructions));
    }
  };

  const addNewItem = useCallback(
    (index: number, e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault(); // Prevent default form submission behavior

      // Create a new item with the appropriate properties
      const newItem = {
        id: uuidv4(),
        prize: `New Segment`,
        weight: 1,
        color: "#000000",
        textColor: "#ffffff",
        isWin: true,
        // This is for the new item; it will be recalculated later
        probabilityPercentage: 100 / (segments.length + 1),
      };

      // Create a new array with the new item added
      const newSegments = [...segments];
      newSegments.splice(index + 1, 0, newItem);

      // Calculate total segments after adding the new item
      const totalSegments = newSegments.length;

      // Update all segments' probability percentages to ensure equal distribution
      const updatedSegments = newSegments.map((segment) => ({
        ...segment, // Spread the existing segment properties
        probabilityPercentage: 100 / totalSegments, // Equal distribution
      }));

      // Dispatch the updated segments array
      dispatch(setSegments(updatedSegments));
    },
    [segments, dispatch],
  );

  const addNewInstrucion = () => {
    dispatch(setInstruction(true));
    const newItem = {
      id: instructions.length + 1,
      text: `Instruction line ${instructions.length + 1}`,
    };

    const newInstructions = [...instructions];
    newInstructions.splice(instructions.length + 1, 0, newItem);
    dispatch(setInstructions(newInstructions));
  };

  const changeInstructionText = (index: number, newText: string) => {
    const updatedInstruction = instructions.map((instruction, i) =>
      i === index ? { ...instruction, text: newText } : instruction,
    );
    dispatch(setInstructions(updatedInstruction));
  };

  const deleteInstruction = (idToDelete: number) => {
    // Filter out the instruction with the matching id
    const newInstructions = instructions.filter(
      (instruction) => instruction.id !== idToDelete,
    );

    // Check if there are any instructions left
    if (newInstructions.length === 0) {
      dispatch(setInstructions([]));
      dispatch(setInstruction(false));
    } else {
      // Update the text labels based on the remaining instructions
      const updatedInstructions = newInstructions.map(
        (instruction: any, index: number) => {
          if (instruction.text === `Instruction line ${instruction.id}`) {
            return {
              ...instruction,
              id: index + 1,
              text: `Instruction line ${index + 1}`, // Update text if condition is met
            };
          } else {
            return {
              ...instruction,
              id: index + 1,
              // Keep original text if condition is not met
            };
          }
        },
      );

      // Update state with the newly labeled instructions
      dispatch(setInstructions(updatedInstructions));
    }
  };

  const deleteItem = (index: number) => {
    // Create a new array by removing the item at the specified index
    const newSegments = segments.filter((_, idx) => idx !== index);

    // Calculate the total number of remaining segments
    const totalSegments = newSegments.length;

    // Update all segments' probability percentages to ensure equal distribution
    const updatedSegments = newSegments.map((segment) => ({
      ...segment,
      probabilityPercentage: totalSegments > 0 ? 100 / totalSegments : 0, // Handle case when no segments left
    }));

    // Dispatch the updated segments
    dispatch(setSegments(updatedSegments));
  };

  const duplicateItem = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number,
  ) => {
    e.preventDefault(); // Prevent default form submission behavior

    const segmentToDuplicate = segments[index];

    // Create a new item with a new id
    const newItem = { ...segmentToDuplicate, id: uuidv4() };

    // Create a new segments array and insert the new item
    const newSegments = [...segments];
    newSegments.splice(index + 1, 0, newItem);

    // Recalculate probability percentages
    const totalSegments = newSegments.length;
    const updatedSegments = newSegments.map((segment) => ({
      ...segment,
      probabilityPercentage: totalSegments > 0 ? 100 / totalSegments : 0,
    }));

    // Dispatch the updated segments
    dispatch(setSegments(updatedSegments));
  };

  const changeSegmentText = (index: number, newText: string) => {
    const updatedSegments = [...segments]; // Create a copy of the segments array
    const segmentToUpdate = { ...updatedSegments[index] }; // Create a copy of the segment to update

    segmentToUpdate.prize = newText; // Update the segment text
    updatedSegments[index] = segmentToUpdate; // Replace the segment in the array with the updated one

    dispatch(setSegments(updatedSegments)); // Update the state with the modified segments array
  };

  const handleDeleteLogo = () => {
    dispatch(setLogo(""));
  };

  const handleDeleteFavicon = () => {
    dispatch(setFaviconUrl(""));
  };

  const updateLogo = async (file: any): Promise<void> => {
    const reader = new FileReader();

    reader.onloadend = function () {
      const result = reader.result as string;
      const image = new Image();
      image.src = result;

      image.onload = () => {
        const width = image.width;
        const height = image.height;
        const MIN_WIDTH = 1366;
        const MIN_HEIGHT = 768;
        const MAX_WIDTH = 1920;
        const MAX_HEIGHT = 1080;
        const MAX_FILE_SIZE_MB = 2;

        // Perform any necessary validations here based on the image dimensions
      };
    };

    const formData = new FormData();

    if (file) {
      reader.readAsDataURL(file);
      formData.append("file", file);

      try {
        const fileName = await uploadLogoImage(file);

        if (fileName) {
          dispatch(setLogo(fileName));
        } else {
          console.log("Failed to upload logo image.");
        }
      } catch (error) {
        console.error("Error uploading logo image:", error);
      }
    } else {
      console.error("No file selected.");
    }
  };

  const onDropLogoImage = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const result = e.target?.result as string;

        updateLogo(file);
      };
      reader.readAsDataURL(file);
    }
  }, []);

  const updateFavicon = async (file: any): Promise<void> => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const result = reader.result as string;
      const image = new Image();
      image.src = result;

      image.onload = () => {
        const width = image.width;
        const height = image.height;
        const MIN_WIDTH = 1366;
        const MIN_HEIGHT = 768;
        const MAX_WIDTH = 1920;
        const MAX_HEIGHT = 1080;
        const MAX_FILE_SIZE_MB = 2;

        // Perform any necessary validations here based on the image dimensions
      };
    };

    const formData = new FormData();

    if (file) {
      reader.readAsDataURL(file);
      formData.append("file", file);

      try {
        const fileName = await uploadFavicon(file);

        if (fileName) {
          dispatch(setFaviconUrl(fileName));
        } else {
          console.log("Failed to upload background image.");
        }
      } catch (error) {
        console.error("Error uploading background image:", error);
      }
    } else {
      console.error("No file selected.");
      document.body.style.backgroundImage = "";
    }
  };

  const onDropFavIcon = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const result = e.target?.result as string;

        updateFavicon(file);
      };
      reader.readAsDataURL(file);
    }
  }, []);

  const { getRootProps: getRootPropsLogo, getInputProps: getInputPropsLogo } =
    useDropzone({
      onDrop: onDropLogoImage,
      accept: { "image/*": [] },
      multiple: false,
    });

  const {
    getRootProps: getRootPropsFavIcon,
    getInputProps: getInputPropsFavIcon,
  } = useDropzone({
    onDrop: onDropFavIcon,
    accept: { "image/*": [] },
    multiple: false,
  });

  const handleTitleBlur = () => {
    if (!localTitle.trim()) {
      setLocalTitle(title || "Spin the Wheel");
      dispatch(setTitle("Spin the Wheel"));
    }
    setIsTitleFocused(false);
  };

  const handleDescriptionBlur = () => {
    if (!localDescription.trim()) {
      setLocalDescription(
        description || "It's simple, fun, and filled with surprises!",
      );
      dispatch(setDescription("It's simple, fun, and filled with surprises!"));
    }
    setIsDescriptionFocused(false);
  };

  useEffect(() => {
    setLocalDescription(description);
    setLocalTitle(title);
  }, [title, description]);

  return (
    <div className="flex flex-col gap-y-2 pb-[5rem] xl:pb-5">
      <div className="relative mb-2">
        <label className="block text-start text-base font-semibold">Logo</label>
        <p className="mb-4 text-sm text-gray-500">
          We support png, gif, jpg, and svg
        </p>
        <div>
          {logo ? (
            <div className="mb-4 mt-6 flex items-center justify-between space-x-4 rounded-md border border-[#dadde1] bg-[#f5f4f7] p-4 text-center shadow-sm outline-none hover:border-blue-500 hover:ring-1 focus:border-transparent focus:outline-none focus:ring-blue-500">
              <img
                src={`${REACT_APP_S3_URL}/spin-the-wheel/logo/${logo}`}
                alt="Uploaded"
                className="h-20 w-20 rounded-md border border-[#dadde1] bg-white object-contain"
              />
              <button
                className="flex items-center rounded-md text-base font-medium text-primary underline"
                {...getRootPropsLogo()}
              >
                Update logo
              </button>
              <button
                className="flex items-center text-primary"
                onClick={handleDeleteLogo}
              >
                <Trash className="mr-2 h-5" />
              </button>
              <input {...getInputPropsLogo()} />
            </div>
          ) : (
            <div
              {...getRootPropsLogo()}
              className="mb-4 mt-6 flex h-28 cursor-pointer items-center justify-start rounded-md border border-[#dadde1] p-6 text-center hover:border-blue-500 hover:ring-1"
            >
              <input {...getInputPropsLogo()} />
              <div className="absolute flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  preserveAspectRatio="xMidYMid meet"
                  width="64"
                  height="64"
                  viewBox="0 0 48 48"
                  className="ml-10"
                >
                  <g color="inherit">
                    <g
                      style={{
                        opacity: "var(--dropzone-icon-opacity, .5)",
                      }}
                    >
                      <circle
                        cx="27.84"
                        cy="15.36"
                        r="3.84"
                        style={{
                          transition: "all 0.3s ease 0s",
                          transform:
                            "var(--dropzone-icon-primary-transform, none)",
                          fill: "var(--dropzone-icon-primary, #161617)",
                        }}
                      ></circle>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M26.3553 27.7139L19.92 19.44L8.16 34.56L21.12 34.56L31.68 34.56L39.84 34.56L30.48 22.32L26.3553 27.7139Z"
                        style={{
                          transition: "all 0.3s ease 0s",
                          fill: "var(--dropzone-icon-secondary, rgba(0, 0, 0, 0.55))",
                        }}
                      ></path>
                    </g>
                  </g>
                </svg>
                <p className="w-40 text-primary">
                  Drag and drop or{" "}
                  <span className="cursor-pointer text-black underline">
                    Click to add logo
                  </span>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      {logo && (
        <div className="relative">
          <label className="mb-3 block text-start text-base font-semibold">
            Website URL
          </label>
          <input
            type="text"
            value={LogoUrl}
            onChange={(e) => dispatch(setLogoUrl(e.target.value))}
            placeholder="Enter website URL"
            className="mb-5 mt-3 block h-10 w-full rounded-md border-2 border-transparent bg-[#f5f5f6] px-3 py-2 pr-8 text-[15px] outline-none hover:border-blue-500 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      )}
      <div className="mb-4 border-b border-gray-200"></div>
      <div className="relative flex flex-col">
        <label
          className="relative block text-start text-base font-semibold"
          htmlFor="Title"
        >
          {" "}
          Title
        </label>
        <input
          value={localTitle}
          onChange={(e) => {
            const newValue = e.target.value;
            setLocalTitle(newValue);
            dispatch(setTitle(newValue));
          }}
          onFocus={() => setIsTitleFocused(true)}
          onBlur={handleTitleBlur}
          maxLength={80}
          placeholder="Spin the  wheel"
          className="mt-3 block h-10 w-full rounded-md border-2 border-transparent bg-[#f5f5f6] px-3 text-[15px] outline-none hover:border-blue-500 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500"
          type="text"
        />
        {isTitleFocused && (
          <p className="absolute inset-y-0 right-0 top-10 flex items-center pr-4 text-xs">
            {80 - title.length}
          </p>
        )}
      </div>
      <div className="relative my-4 flex flex-col">
        <label
          className="block text-start text-base font-semibold"
          htmlFor="Description"
        >
          {" "}
          Description
        </label>
        <textarea
          value={localDescription}
          maxLength={100}
          onChange={(e) => {
            const newValue = e.target.value;
            setLocalDescription(newValue);
            dispatch(setDescription(newValue));
          }}
          onBlur={handleDescriptionBlur}
          onFocus={() => setIsDescriptionFocused(true)}
          placeholder="Description"
          className="mt-3 block h-16 w-full rounded-md border-2 border-transparent bg-[#f5f5f6] px-3 py-2 pr-8 text-[15px] outline-none hover:border-blue-500 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500"
          rows={3}
        />
        {isDescriptionFocused && (
          <p className="absolute inset-y-0 right-0 top-14 flex items-center pr-4 text-xs">
            {100 - description.length}
          </p>
        )}
      </div>

      <div className="mb-4 flex items-center justify-between">
        <div className="flex flex-col">
          <label className="block text-base font-semibold">
            Add favicon <br />{" "}
          </label>
          {/* <span className="text-[13px] text-gray-500">
            {" "}
            only 16x16px is supported
          </span> */}
        </div>
        <Switch
          checked={FaviconUrl ? true : false}
          onChange={() => {
            dispatch(setFavIcon(!FavIcon));
          }}
          className={`${FavIcon ? "bg-blue-500" : "bg-gray-300"} relative inline-flex h-6 w-11 items-center rounded-full`}
        >
          <span
            className={`${
              FavIcon ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
          />
        </Switch>
      </div>
      {FavIcon &&
        (FaviconUrl ? (
          <div className="mb-4 mt-2 flex items-center justify-between space-x-4 rounded-md border border-[#dadde1] bg-[#f5f4f7] p-4 text-center hover:border-primary">
            <img
              src={`${REACT_APP_S3_URL}/spin-the-wheel/favicon/${FaviconUrl}`}
              alt="Uploaded"
              className="h-20 w-20 rounded-md border border-[#dadde1]"
            />

            <button
              className="flex items-center rounded-md text-base font-medium text-primary underline"
              {...getRootPropsFavIcon()}
            >
              Update image
            </button>
            <button
              onClick={handleDeleteFavicon}
              className="flex items-center text-primary"
            >
              <Trash className="mr-2 h-5" />
            </button>

            <input {...getInputPropsFavIcon()} />
          </div>
        ) : (
          <div
            {...getRootPropsFavIcon()}
            className="mb-4 mt-2 flex cursor-pointer items-center justify-start rounded-md border border-[#dadde1] p-6 text-center hover:border-primary"
          >
            <input {...getInputPropsFavIcon()} />
            <div className="flex items-center justify-start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                preserveAspectRatio="xMidYMid meet"
                width="64"
                height="64"
                viewBox="0 0 48 48"
                className="ml-10"
              >
                <g color="inherit">
                  <g
                    style={{
                      opacity: "var(--dropzone-icon-opacity, .5)",
                    }}
                  >
                    <circle
                      cx="27.84"
                      cy="15.36"
                      r="3.84"
                      style={{
                        transition: "all 0.3s ease 0s",
                        transform:
                          "var(--dropzone-icon-primary-transform, none)",
                        fill: "var(--dropzone-icon-primary, #161617)",
                      }}
                    ></circle>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M26.3553 27.7139L19.92 19.44L8.16 34.56L21.12 34.56L31.68 34.56L39.84 34.56L30.48 22.32L26.3553 27.7139Z"
                      style={{
                        transition: "all 0.3s ease 0s",
                        fill: "var(--dropzone-icon-secondary, rgba(0, 0, 0, 0.55))",
                      }}
                    ></path>
                  </g>
                </g>
              </svg>
              <p className="w-40 text-primary">
                Drag and drop or{" "}
                <span className="cursor-pointer text-black underline">
                  Click to add favicon
                </span>
              </p>
            </div>
          </div>
        ))}

      <div className="flex items-center justify-between">
        <label className="block text-base font-semibold">
          Instructions/Rules for game play
        </label>
        <Switch
          checked={instruction}
          onChange={handleDisplayInstructionsToggle}
          className={`${instruction ? "bg-blue-500" : "bg-gray-300"} relative inline-flex h-6 w-11 items-center rounded-full`}
        >
          <span className="sr-only">Display Rules/Instructions</span>
          <span
            className={`${
              instruction ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
          />
        </Switch>
      </div>
      {instruction && (
        <div className="mt-4 space-y-4">
          {instructions.map((instruction, index) => (
            <div
              key={instruction.id}
              className="relative flex items-center space-x-2"
            >
              <input
                type="text"
                value={instruction.text}
                onChange={(e) => changeInstructionText(index, e.target.value)}
                onBlur={() => handleInstructionBlur(instruction.id, index)}
                onFocus={() => setFocusedInstruction(instruction.id)}
                placeholder={`Instruction line ${index + 1}`}
                className="block w-full rounded-md border-2 border-transparent bg-[#f5f5f6] px-3 py-2 pr-8 text-[15px] outline-none hover:border-blue-500 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <span
                className={`absolute bottom-3 right-16 text-xs font-medium text-primary ${
                  focusedInstruction === instruction.id ? "" : "hidden"
                }`}
                style={{ zIndex: 10 }}
              >
                {80 - instruction.text.length}
              </span>
              <button
                onClick={() => deleteInstruction(instruction.id)}
                className="flex h-10 w-12 items-center justify-center rounded-lg bg-[#f5f5f6] text-[#adaeaf] hover:bg-[#edecf1]"
              >
                <X className="h-5" />
              </button>
            </div>
          ))}
          {instruction && (
            <div>
              {instructions.length < 3 ? (
                <button
                  className="mt-4 flex w-full items-center justify-center rounded-full bg-[#f0f0f1] p-3 text-center text-base font-semibold text-primary hover:bg-[#edecf1]"
                  onClick={addNewInstrucion}
                >
                  <Plus className="mr-2 h-5" /> Add Instruction
                </button>
              ) : (
                <div className="mt-4 rounded-lg bg-blue-100 p-3 text-sm text-blue-700">
                  Max limit of 3 instructions reached
                </div>
              )}
            </div>
          )}
        </div>
      )}
      <div className="my-4 border-b border-gray-200"></div>
      <div className="my-2 flex w-full flex-col items-center justify-start gap-y-2">
        <div className="specifications flex flex-row items-center justify-center rounded-full bg-[#f4f5f7] px-1.5 py-1.5">
          {" "}
          <button
            onClick={() => handleSpecification("Segments")}
            className={`w-40 rounded-full px-4 py-2.5 ${
              specification === "Segments"
                ? "border border-secondary bg-white font-medium"
                : ""
            }`}
          >
            Segments
          </button>
          <button
            onClick={() => handleSpecification("Themes")}
            className={`w-40 rounded-full px-4 py-2.5 ${
              specification === "Themes"
                ? "border border-secondary bg-white font-medium"
                : ""
            }`}
          >
            Themes
          </button>{" "}
        </div>
        <div className="flex w-full flex-col items-start">
          <div className="my-2">
            {specification === "Segments" ? (
              <p className="ml-2 text-start font-semibold">
                Add or duplicate segments
              </p>
            ) : (
              <p className="ml-2 text-start font-semibold">
                Change theme of segments
              </p>
            )}
          </div>
          {segments.map((segment, index) => (
            <div
              key={segment.id}
              className="my-1.5 flex w-full flex-col justify-start rounded-lg bg-[white]"
            >
              <div className="relative flex h-10 w-full flex-row gap-x-1 px-1 md:gap-x-2">
                <input
                  type="text"
                  disabled={specification === "Themes"}
                  defaultValue={segment.prize}
                  maxLength={40}
                  onFocus={() => setFocusedSegment(index)}
                  onBlur={handleSegmentBlur}
                  onChange={(e) => {
                    const newErrors = [...errors];
                    changeSegmentText(index, e.target.value);
                    if (!e.target.value) {
                      newErrors[index] = true;
                    } else {
                      newErrors[index] = false;
                    }
                    setErrors(newErrors);
                  }}
                  className={`w-full rounded-lg border-2 border-transparent px-2 py-1 text-[15px] text-black ${
                    errors[index] ? "border-red-500" : "border-secondary"
                  } bg-[#f4f5f7] hover:border-blue-500 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500`}
                />
                <span
                  className={`absolute bottom-3 right-[9.9rem] text-xs font-medium text-primary ${
                    focusedSegment === index ? "" : "hidden"
                  }`}
                  style={{ zIndex: 10 }}
                >
                  {40 - segment.prize.length}
                </span>

                {specification === "Themes" && (
                  <ColorInput
                    id={`${segment.id}`}
                    defaultColor={segment.color}
                    Index={index}
                    isText={false}
                  />
                )}

                {specification === "Themes" && (
                  <ColorInput
                    id={`${segment.id}`}
                    defaultColor={segment.textColor}
                    Index={index}
                    isText={true}
                  />
                )}

                {specification === "Segments" && (
                  <div className="flex flex-row gap-x-2">
                    <button
                      disabled={segments.length > 12}
                      onClick={(e) => duplicateItem(e, index)}
                      className="flex h-10 w-10 items-center justify-center rounded-lg bg-[#f5f5f6] text-[#adaeaf] hover:bg-[#edecf1]"
                    >
                      <BiDuplicate className="text-gray-500" size={18} />
                    </button>
                    <button
                      disabled={segments.length > 12}
                      onClick={(e) => addNewItem(index, e)}
                      className="flex h-10 w-10 items-center justify-center rounded-lg bg-[#f5f5f6] text-[#adaeaf] hover:bg-[#edecf1]"
                    >
                      {" "}
                      <Plus className="text-gray-500" size={20} />
                    </button>
                    <button
                      onClick={() => {
                        const newErrors = [...errors];
                        newErrors[index] = false;
                        setErrors(newErrors);
                        deleteItem(index);
                      }}
                      className="flex h-10 w-10 items-center justify-center rounded-lg bg-[#f5f5f6] text-[#adaeaf] hover:bg-[#edecf1]"
                    >
                      {" "}
                      <Trash className="text-gray-500" size={18} />{" "}
                    </button>
                  </div>
                )}
              </div>
              {errors[index] && (
                <div className="ml-2 mt-2 text-sm text-red-500">
                  This field cannot be empty
                </div>
              )}
            </div>
          ))}
        </div>
        {segments.length > 12 && (
          <div className="w-full rounded-lg bg-blue-100 p-3 text-center text-sm text-blue-700">
            Max limit of 12 segments reached
          </div>
        )}
      </div>
    </div>
  );
};

export default Specifications;
