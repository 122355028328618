import React, { useEffect, useRef, useLayoutEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import WheelPopUp from "./WheelPopUp";
import { fetchData } from "../api/fetchGameDataAPI";
import { AppDispatch } from "../store/store";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/store";
import {
  setPreview,
  setShowWheelPopup,
  setSpinning,
  setSelected,
  setCurrentScreen,
} from "../store/slice/CustomizeSlice";
import sampleImg from "../assets/SampleBackgroundImage.webp";
import toast from "react-hot-toast";
import { fetchUserSession, UserSessionParams } from "../api/fetchUserSession";

const { REACT_APP_S3_URL } = process.env;

interface GameProps {
  isCutomize: boolean;
}

const Game: React.FC<GameProps> = ({ isCutomize }) => {
  const navigate = useNavigate();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const {
    segments,
    fontSize,
    fontFamily,
    backgroundColor,
    showWheelPopup,
    spinning,
    selected,
    backgroundIMG,
    preview,
    loader,
    inAppParams,
    logo,
    LogoUrl,
  } = useSelector((state: RootState) => state.customize);
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const gameId = queryParams.get("gameId") || sessionStorage.getItem("gameId");
  const [logoSize, setLogoSize] = useState({ width: 0, height: 0 });
  const isCustomisePage = location.pathname === "/";
  const bckImg = backgroundIMG || sessionStorage.getItem("backgroundImg");
  const GameRef = useRef<HTMLDivElement | null>(null);

  type SegmentWithCumulative = (typeof segments)[0] & { cumulative: number };

  const handleSpin = async () => {
    if (!spinning && preview) {
      dispatch(setSpinning(true));

      // Ensure there are segments to spin
      if (segments.length === 0) {
        console.error("No segments to spin.");
        return;
      }

      const firstElement = segments[0];
      const reversedRest = segments.slice(1).reverse();
      const win = [firstElement, ...reversedRest];

      // Filter out segments with zero probability
      const validSegments = win.filter(
        (segment) => segment.probabilityPercentage > 0,
      );

      if (validSegments.length === 0) {
        console.error("No valid segments to spin.");
        return;
      }

      // Calculate the cumulative probability ranges
      const cumulativeProbabilities: SegmentWithCumulative[] =
        validSegments.reduce((acc, segment, index) => {
          const lastProbability = acc[index - 1]
            ? acc[index - 1].cumulative
            : 0;
          const cumulative = lastProbability + segment.probabilityPercentage;
          acc.push({ ...segment, cumulative });
          return acc;
        }, [] as SegmentWithCumulative[]);

      // Generate a random number between 0 and 100
      const randomProbability = Math.random() * 100;

      // Find the selected segment based on the random probability
      const selectedSegment = cumulativeProbabilities.find(
        (segment) => randomProbability <= segment.cumulative,
      );

      if (!selectedSegment) {
        console.error("Failed to select a segment.");
        dispatch(setSpinning(false));
        return;
      }

      // Calculate the angle for the chosen segment
      const segmentAngle = 360 / segments.length;
      const selectedSegmentIndex = win.findIndex(
        (s) => s.id === selectedSegment.id,
      );
      const randomAngle = selectedSegmentIndex * segmentAngle + 3600;

      dispatch(setSelected(selectedSegment));
      console.log("Selected Segment:", selectedSegment);

      const canvas = canvasRef.current;
      if (canvas) {
        // Apply CSS transition to smoothly rotate the wheel
        canvas.style.transition = "transform 5s ease-out";
        canvas.style.transform = `rotate(${randomAngle}deg)`;

        // After the spin animation ends
        setTimeout(() => {
          // Reset styles to stop spinning
          canvas.style.animation = "none";
          canvas.style.transition = "none";
          canvas.style.transform = `none`;
          dispatch(setSpinning(false));
          if (preview || !isCustomisePage) dispatch(setShowWheelPopup(true));
        }, 6000); // Adjust timeout to match CSS animation duration
      }
    }

    if (!spinning && !preview && gameId) {
      if (!inAppParams) {
        dispatch(setSpinning(true));

        // Ensure there are segments to spin
        if (segments.length === 0) {
          console.error("No segments to spin.");
          return;
        }

        const firstElement = segments[0];
        const reversedRest = segments.slice(1).reverse();
        const win = [firstElement, ...reversedRest];

        // Filter out segments with zero probability
        const validSegments = win.filter(
          (segment) => segment.probabilityPercentage > 0,
        );

        if (validSegments.length === 0) {
          console.error("No valid segments to spin.");
          return;
        }

        // Calculate the cumulative probability ranges
        const cumulativeProbabilities: SegmentWithCumulative[] =
          validSegments.reduce((acc, segment, index) => {
            const lastProbability = acc[index - 1]
              ? acc[index - 1].cumulative
              : 0;
            const cumulative = lastProbability + segment.probabilityPercentage;
            acc.push({ ...segment, cumulative });
            return acc;
          }, [] as SegmentWithCumulative[]);

        // Generate a random number between 0 and 100
        const randomProbability = Math.random() * 100;

        // Find the selected segment based on the random probability
        const selectedSegment = cumulativeProbabilities.find(
          (segment) => randomProbability <= segment.cumulative,
        );

        if (!selectedSegment) {
          console.error("Failed to select a segment.");
          dispatch(setSpinning(false));
          return;
        }

        // Calculate the angle for the chosen segment
        const segmentAngle = 360 / segments.length;
        const selectedSegmentIndex = win.findIndex(
          (s) => s.id === selectedSegment.id,
        );
        const randomAngle = selectedSegmentIndex * segmentAngle + 3600;

        dispatch(setSelected(selectedSegment));
        console.log("Selected Segment:", selectedSegment);

        const canvas = canvasRef.current;
        if (canvas) {
          // Apply CSS transition to smoothly rotate the wheel
          canvas.style.transition = "transform 5s ease-out";
          canvas.style.transform = `rotate(${randomAngle}deg)`;

          // After the spin animation ends
          setTimeout(() => {
            // Reset styles to stop spinning
            canvas.style.animation = "none";
            canvas.style.transition = "none";
            canvas.style.transform = `none`;
            dispatch(setSpinning(false));
            if (preview || !isCustomisePage) dispatch(setShowWheelPopup(true));
          }, 6000); // Adjust timeout to match CSS animation duration
        }
      }

      if (inAppParams) {
        try {
          // Get UID and extra params from the URL
          const urlParams = new URLSearchParams(window.location.search);
          const uid = urlParams.get("uid");
          if (!uid) {
            toast.error("UID is missing");
            console.log("Error: UID is missing from the URL parameters.");
            return;
          }

          // Collect extra parameters from the URL
          const extraParams: Record<string, any> = {};
          urlParams.forEach((value, key) => {
            if (key !== "gameId" && key !== "uid") {
              extraParams[key] = value;
            }
          });
          // console.log("Extra Params:", extraParams);

          // Call fetchUserSession API
          const userSessionParams: UserSessionParams = {
            gameId,
            uid,
            extraParams,
          };
          const sessionData = await fetchUserSession(userSessionParams);

          // console.log("API Response Data:", sessionData);

          if (!sessionData?.prize) {
            toast.error("No prize available for this session.");
            console.log("Error: No prize in session data response.");
            return;
          }

          // Prepare the segments order with first segment and reversed rest
          const firstElement = segments[0];
          const reversedRest = segments.slice(1).reverse();
          const win = [firstElement, ...reversedRest];
          // console.log("Segment Order (win):", win);

          // Find the target segment based on the prize
          const targetSegment = win.find(
            (segment) => segment.prize === sessionData.prize,
          );

          if (!targetSegment) {
            toast.error("Prize segment not found.");
            console.log(
              "Error: Target segment not found for prize:",
              sessionData.prize,
            );
            return;
          }

          // console.log("Target Segment based on prize:", targetSegment);

          // Set spinning state and calculate the angle
          dispatch(setSpinning(true));
          const segmentAngle = 360 / win.length;
          const targetSegmentIndex = win.findIndex(
            (segment) => segment.id === targetSegment.id,
          );

          if (targetSegmentIndex === -1) {
            console.error("Error: Target segment index not found.");
            return;
          }

          // console.log("Target Segment Index:", targetSegmentIndex);
          const randomAngle = targetSegmentIndex * segmentAngle + 3600;

          dispatch(setSelected(targetSegment));
          // console.log("Selected Segment:", targetSegment);

          const canvas = canvasRef.current;
          if (canvas) {
            canvas.style.transition = "transform 5s ease-out";
            canvas.style.transform = `rotate(${randomAngle}deg)`;

            setTimeout(() => {
              canvas.style.animation = "none";
              canvas.style.transition = "none";
              canvas.style.transform = `none`;
              dispatch(setSpinning(false));
              if (preview || !isCustomisePage)
                dispatch(setShowWheelPopup(true));
            }, 6000);
          }
        } catch (error: any) {
          toast.error(error.message);
          console.error("Error caught during spin process:", error);
          dispatch(setSpinning(false));
        }
      }
    }
  };

  useLayoutEffect(() => {
    const updateLogoSize = () => {
      if (logo && GameRef.current) {
        const img = new Image();
        img.src = `${REACT_APP_S3_URL}/spin-the-wheel/logo/${logo}`;

        img.onload = () => {
          let screenHeight = GameRef.current?.offsetHeight || 0;
          let screenWidth = GameRef.current?.offsetWidth || 0;
          if (!isCustomisePage || preview) {
            screenHeight = window.innerHeight;
            screenWidth = window.innerWidth;
          }

          const aspectRatio = img.width / img.height;

          let logoHeight: number;
          let logoWidth: number;

          if (aspectRatio > 1.5) {
            const minHeightPercentage = 0.04;
            logoHeight = screenHeight * minHeightPercentage;
          } else {
            const maxHeightPercentage = 0.1;
            logoHeight = screenHeight * maxHeightPercentage;
          }

          logoWidth = logoHeight * aspectRatio;
          const maxWidth = screenWidth * 0.3;

          if (logoWidth > maxWidth) {
            logoWidth = maxWidth;
            logoHeight = maxWidth / aspectRatio;
          }

          setLogoSize({ width: logoWidth, height: logoHeight });
        };
      }
    };

    updateLogoSize();
  }, [logo, GameRef.current?.offsetHeight, GameRef.current?.offsetWidth]);

  useEffect(() => {
    if (!isCustomisePage && gameId) {
      dispatch(fetchData(gameId));
    }
  }, [isCustomisePage, logo]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    if (GameRef) {
      let screenHeight = GameRef.current?.offsetHeight || 0;
      let screenWidth = GameRef.current?.offsetWidth || 0;

      if (!isCustomisePage || preview) {
        screenHeight = window.innerHeight;
        screenWidth = window.innerWidth;
      }

      const size = Math.min(screenHeight, screenWidth) * 0.65;

      canvas.width = size;
      canvas.height = size;
      if (size < 260) {
        canvas.width = 290;
        canvas.height = 290;
      }
    }

    if (!preview && isCustomisePage) {
      dispatch(setShowWheelPopup(false));
    }

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const totalWeightage = segments.reduce(
      (total, segment) => total + segment.weight,
      0,
    );

    let difftoAdd = 0.09;
    if (segments.length > 9) {
      difftoAdd = 0.05;
    }

    const defaultSegments = 6;
    let newStartAnglediff = (segments.length - defaultSegments) * difftoAdd;

    let startAngle = 10.47 + newStartAnglediff;

    for (const segment of segments) {
      const angle = (segment.weight / totalWeightage) * (2 * Math.PI);

      // Calculate segment center
      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;
      const maxDimension = Math.min(canvas.width, canvas.height);
      const radius = (maxDimension / 2) * 1.1; // Use 80% of the canvas dimension as radiusthe radius doesn't exceed canvas dimensions

      // Draw the segment
      ctx.beginPath();
      ctx.moveTo(centerX, centerY);
      ctx.arc(centerX, centerY, radius, startAngle, startAngle + angle);
      ctx.fillStyle = segment.color;
      ctx.fill();

      // Text Wrapping and Drawing
      const words = segment.prize.split(" ");
      const lines: string[] = [];
      let currentLine = "";

      for (let word of words) {
        const testLine = currentLine + word + " ";
        ctx.font = `${fontSize}px ${fontFamily}`;
        const metrics = ctx.measureText(testLine);

        if (metrics.width > radius * 0.6) {
          lines.push(currentLine);
          currentLine = word + " ";
        } else {
          currentLine = testLine;
        }
      }
      lines.push(currentLine);

      // Calculate vertical position and rotation
      const lineHeight = parseFloat(fontSize) * 1.2;
      const segmentAngle = startAngle + angle / 2;

      const textX = centerX + Math.cos(segmentAngle) * (radius / 2);
      const textY = centerY + Math.sin(segmentAngle) * (radius / 2);

      // Center the lines vertically
      const offsetY = -((lines.length - 1) * lineHeight) / 2;

      ctx.textAlign = "center";
      ctx.fillStyle = segment.textColor;

      // Adjust the translation to better center the text vertically
      ctx.save();
      ctx.translate(textX, textY + offsetY); // Move to the center of the segment and adjust with offsetY
      ctx.rotate(segmentAngle); // Rotate based on segment angle

      // Render each line
      lines.forEach((line, index) => {
        const lineOffset = index * lineHeight; // Keep the line height calculation simple
        ctx.fillText(line, 12, lineOffset); // No need for 12, just use 0 for x
      });

      ctx.restore(); // Reset transformations

      startAngle += angle;
    }
  }, [
    segments,
    fontSize,
    fontFamily,
    location.pathname,
    bckImg,
    backgroundColor,
    logo,
    GameRef.current?.offsetHeight,
    preview,
  ]);

  return (
    <div
      ref={GameRef}
      style={{
        backgroundImage:
          backgroundIMG !== "default" && backgroundIMG !== "none"
            ? `url(${REACT_APP_S3_URL}/spin-the-wheel/background/${backgroundIMG})`
            : backgroundIMG !== "none"
              ? `url(${sampleImg})`
              : "none",
        backgroundSize: "cover",
        backgroundColor: backgroundColor,
        backgroundRepeat: "no-repeat",
      }}
      className={`flex flex-col items-center justify-center ${
        !isCustomisePage || preview
          ? "m-0 h-screen w-screen overflow-hidden border-0 p-0"
          : "h-[100vh] overflow-hidden rounded-xl"
      } border border-secondary`}
    >
      {!loader && (
        <div className="flex flex-col items-center justify-center">
          {logo && (
            <div className="mb-4 flex items-center justify-center">
              {LogoUrl ? (
                <a href={LogoUrl} target="_blank" rel="noopener noreferrer">
                  <img
                    loading="lazy"
                    src={`${REACT_APP_S3_URL}/spin-the-wheel/logo/${logo}`}
                    alt="Logo"
                    style={{
                      width: `${logoSize.width}px`,
                      height: `${logoSize.height}px`,
                    }}
                  />
                </a>
              ) : (
                <img
                  loading="lazy"
                  src={`${REACT_APP_S3_URL}/spin-the-wheel/logo/${logo}`}
                  alt="Logo"
                  style={{
                    width: `${logoSize.width}px`,
                    height: `${logoSize.height}px`,
                  }}
                />
              )}
            </div>
          )}
          <div className="WheelShadow flex flex-col items-center justify-center">
            <canvas
              id="Wheel"
              className="rounded-full border-8 border-black bg-black"
              ref={canvasRef}
            />

            <button
              onClick={handleSpin}
              disabled={spinning}
              className="absolute transform cursor-pointer rounded-full p-0"
            >
              <svg
                className="md:h-18 md:w-18 h-16 w-16 lg:h-24 lg:w-24"
                viewBox="-20 -40 40 80"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className=""
                  d="M -8.513980260046061 -23.200596199721776
                   L 0 -38.31291112119587
                   L 8.513980260046061 -23.200596199721776
                   A 25.598076923076924 25.598076923076924 0 1 1 -8.513980260046061 -23.200596199721776
                   Z"
                  fill="black"
                ></path>
                <text
                  fontSize={`${fontSize}px`}
                  fontFamily={`${fontFamily}`}
                  fill="white"
                  textAnchor="middle"
                  y="6.655500000000001"
                  pointerEvents="none"
                >
                  Spin
                </text>
              </svg>
            </button>
          </div>
          {preview && (
            <button
              data-tooltip-id="preview"
              data-tooltip-content="Preview"
              onClick={() => {
                dispatch(setSpinning(false));
                dispatch(setPreview(!preview));
                dispatch(setCurrentScreen(0));
                navigate("/", { replace: true });
              }}
              className="absolute bottom-14 rounded-full bg-black px-4 py-2 text-center text-white hover:scale-105 hover:opacity-80"
            >
              {!preview ? "Preview" : "Exit preview"}
            </button>
          )}
          {showWheelPopup && <WheelPopUp segment={selected} />}
        </div>
      )}
    </div>
  );
};

export default Game;
