import { useEffect, useState, useRef } from "react";
import { FaQuestion, FaUser } from "react-icons/fa";
import TooltipComponent from "./TooltipComponent";
import { Tooltip } from "react-tooltip";

interface Menu {
  name: string;
  icon: any;
}

interface MainSideBarProps {
  menu: Menu[];
  selectedMenu: string;
  setClose: (value: boolean) => void; // Accepts a boolean argument
  setSelectedMenu: (menu: string) => void; // Accepts a string argument
}

const { REACT_APP_S3_URL, REACT_APP_WEBSITE_URL } = process.env;

const MainSideBar: React.FC<MainSideBarProps> = ({
  menu,
  selectedMenu,
  setClose,
  setSelectedMenu,
}) => {
  const [dropdownOpenmain, setDropdownOpenmain] = useState(false);
  const dropdownRef1 = useRef<HTMLDivElement>(null);
  const questionButtonRef1 = useRef<HTMLButtonElement>(null);

  const toggleDropdownmain = () => {
    setDropdownOpenmain((prev) => !prev);
  };

  const handleClick = (menu: string) => {
    setClose(false);
    setSelectedMenu(menu);
  };

  const handleContactSupport = () => {
    window.open(`${REACT_APP_WEBSITE_URL}/contact`, "_blank");
    setDropdownOpenmain(false);
  };

  const handleClickOutside1 = (event: MouseEvent) => {
    if (
      dropdownRef1.current &&
      !dropdownRef1.current.contains(event.target as Node) &&
      questionButtonRef1.current &&
      !questionButtonRef1.current.contains(event.target as Node)
    ) {
      setDropdownOpenmain(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside1);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside1);
    };
  }, []);

  return (
    <div className="item-center hidden h-full flex-col justify-between xl:flex">
      <div className="tool container flex flex-col items-center justify-center gap-y-4">
        <div className="flex flex-col space-y-6 rounded-full bg-primary p-2 shadow-lg">
          {menu.map((item, index) => (
            <div
              key={item.name}
              data-tooltip-id={item.name}
              data-tooltip-content={item.name}
              onClick={() => handleClick(item.name)}
              className={`cursor-pointer rounded-full p-4 hover:scale-105 ${
                selectedMenu === item.name
                  ? "bg-secondary text-primary"
                  : "bg-primary text-secondary"
              }`}
            >
              {" "}
              <item.icon size={24} />
              <Tooltip
                id={item.name}
                place="right"
                noArrow
                offset={16}
                delayHide={0}
                style={{ borderRadius: "20px" }}
              ></Tooltip>
            </div>
          ))}
        </div>
        <div className="flex flex-col items-center rounded-full bg-primary p-2 shadow-lg">
          <TooltipComponent
            id="templates"
            content="Templates - Coming Soon!"
            place="right"
            offset={30}
          >
            <button className="item-center flex flex-col justify-center rounded-full p-4 text-secondary hover:scale-105">
              <img
                src={`${REACT_APP_S3_URL}/assets/icons/template.webp`}
                alt="template"
                className="h-7"
              />
            </button>
          </TooltipComponent>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center rounded-full">
        <button
          ref={questionButtonRef1}
          className={`flex h-10 w-10 flex-col items-center justify-center rounded-full bg-[#202022] hover:scale-105 ${
            dropdownOpenmain
              ? "animate-pulse ring-4 ring-gray-400 ring-opacity-50"
              : ""
          }`}
          onClick={toggleDropdownmain}
          data-tooltip-id="help"
          data-tooltip-content="Help"
        >
          <FaQuestion size={16} className="text-white" />
        </button>
        {dropdownOpenmain && (
          <div
            style={{ position: "absolute", zIndex: 99999 }}
            ref={dropdownRef1}
            className="absolute bottom-20 right-10 z-40 w-48 rounded-xl bg-white shadow"
          >
            <div className="z-80 absolute right-3 top-[calc(100%-1px)] h-0 w-0 border-x-8 border-t-8 border-x-transparent border-t-white drop-shadow"></div>
            <ul onClick={handleContactSupport} className="p-2">
              <li className="flex cursor-pointer items-center space-x-2 rounded-lg p-2 hover:bg-gray-100">
                <FaUser size={16} />
                <span>Contact support</span>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default MainSideBar;
