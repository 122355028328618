import { useEffect, useState, useLayoutEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { fetchData } from "../api/fetchGameDataAPI";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { List, X } from "react-feather";
import ClipLoader from "react-spinners/ClipLoader";
import "../index.css";
import toast from "react-hot-toast";
import { ChevronLeft, ChevronRight } from "react-feather";
import { Toaster } from "react-hot-toast";
import { AppDispatch } from "../../src/store/store";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { useWindowSize } from "react-use";
import {
  setPreview,
  setCurrentScreen,
  setUser,
} from "../store/slice/CustomizeSlice";
import { useDispatch } from "react-redux";
import BuiltWithWorkplay from "../Components/BuiltWithWorkplay";
import sampleImg from "../assets/SampleBackgroundImage.webp";
import LinkActiveOrExpiredPage from "./LinkActiveOrExpiredPage";
import { checkUserSession } from "../api/checkUserSession";

const { REACT_APP_S3_URL } = process.env;

interface HomeProps {}

const Home: React.FC<HomeProps> = ({}) => {
  const {
    fontFamily,
    backgroundColor,
    backgroundIMG,
    title,
    description,
    instructions,
    textColor,
    secondaryColor,
    instruction,
    preview,
    user,
    loader,
    logo,
    LogoUrl,
    FaviconUrl,
    currentScreen,
  } = useSelector((state: RootState) => state.customize);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [status, setStatus] = useState<number | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [showInstructions, setShowInstructions] = useState(false);
  const location = useLocation();
  const isCustomisePage = location.pathname === "/";
  const queryParams = new URLSearchParams(location.search);
  const [logoSize, setLogoSize] = useState({ width: 0, height: 0 });
  const { inAppParams } = useSelector((state: RootState) => state.customize);

  // Calculate input field width based on golden ratio

  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const HomeRef = useRef<HTMLDivElement | null>(null);
  const gameId = queryParams.get("gameId") || sessionStorage.getItem("gameId");

  const handlePlayButtonClick = async () => {
    if (inAppParams) {
      const urlParams = new URLSearchParams(window.location.search);
      const uid = urlParams.get("uid");

      if (!uid) {
        toast.error("UID is missing");
        return;
      }

      // const extraParams = Array.from(urlParams.entries()).filter(
      //   ([key]) => key !== "uid",
      // );

      const extraParams = Array.from(urlParams.entries())
        .filter(([key]) => key !== "uid" && key !== "gameId")
        .reduce(
          (acc, [key, value]) => {
            acc[key] = value;
            return acc;
          },
          {} as Record<string, string>,
        );

      // if (extraParams.length > 0) {
      //   console.log("Extra parameters present in URL:", extraParams);
      //   console.log("uid", uid);
      // }

      dispatch(setCurrentScreen(1));
      if (gameId && !preview) {
        await handleUserWithParams(gameId, uid, extraParams);
      }
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!user.name && !user.email) {
        toast.error("Name and email are required");
        return;
      } else if (!user.name) {
        toast.error("Name is required");
        return;
      } else if (!user.email) {
        toast.error("email is required");
      } else if (!emailRegex.test(user.email)) {
        toast.error("Invalid email format");
      } else if (user.email && user.name) {
        dispatch(setCurrentScreen(1));
        if (gameId && !preview) {
          // handleUser(gameId);
          handleUserWithoutParams(gameId);
        }
      }
    }
  };

  const handleUserWithParams = async (
    gameId: string,
    uid: string,
    extraParams: Record<string, any>,
  ) => {
    try {
      const response = await checkUserSession(gameId, null, uid, extraParams);

      if (response.success) {
        console.log("User session fetched successfully.");
        toast.error("You've already played the game");
      } else {
        // console.log("User session not found.");
        const urlParams = new URLSearchParams(window.location.search);
        const uid = urlParams.get("uid");

        if (!uid) {
          toast.error("UID is missing");
          return;
        }
        const extraParams = Array.from(urlParams.entries()).filter(
          ([key]) => key !== "gameId",
        );

        // Initialize the URL with gameId
        let url = `/game?gameId=${gameId}`;

        // Append any additional parameters
        extraParams.forEach(([key, value]) => {
          url += `&${key}=${value}`;
        });
        navigate(url);
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        toast.error(error.response?.data?.message || "An error occurred.");
        console.error("API error:", error.message);
      } else {
        console.error("Unknown error:", error);
      }
    }
  };

  const handleUserWithoutParams = async (gameId: string) => {
    try {
      const response = await checkUserSession(gameId, user.email);

      if (response.success) {
        console.log("User session fetched successfully.");
        toast.error("You've already played the game");
      } else {
        // console.log("User session not found.");
        navigate(`/game?gameId=${gameId}`);
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        toast.error(error.response?.data?.message || "An error occurred.");
        console.error("API error:", error.message);
      } else {
        console.error("Unknown error:", error);
      }
    }
  };

  useLayoutEffect(() => {
    const updateLogoSize = () => {
      if (logo && HomeRef.current) {
        const img = new Image();
        img.src = `${REACT_APP_S3_URL}/spin-the-wheel/logo/${logo}`;

        img.onload = () => {
          const screenHeight = HomeRef.current?.offsetHeight || 0;
          const screenWidth = HomeRef.current?.offsetWidth || 0;

          const aspectRatio = img.width / img.height;

          let logoHeight: number;
          let logoWidth: number;

          if (aspectRatio > 1.5) {
            const minHeightPercentage = 0.04;
            logoHeight = screenHeight * minHeightPercentage;
          } else {
            const maxHeightPercentage = 0.1;
            logoHeight = screenHeight * maxHeightPercentage;
          }

          logoWidth = logoHeight * aspectRatio;
          const maxWidth = screenWidth * 0.3;

          if (logoWidth > maxWidth) {
            logoWidth = maxWidth;
            logoHeight = maxWidth / aspectRatio;
          }

          setLogoSize({ width: logoWidth, height: logoHeight });
        };
      }
    };

    updateLogoSize();
  }, [logo, windowWidth]);

  useEffect(() => {
    const fetchGameData = async () => {
      if (gameId) {
        try {
          const result = await dispatch(fetchData(gameId));
          if (result) {
            setStatus(result.status);
            setMessage(result.message);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    if (!isCustomisePage) {
      // Fetch game data
      fetchGameData();

      // Set document title
      document.title = title;

      // Update favicon
      const link: HTMLLinkElement | null =
        document.querySelector("link[rel~='icon']");
      if (link) {
        link.href = `${REACT_APP_S3_URL}/spin-the-wheel/favicon/${FaviconUrl}`;
      }
    }
  }, [gameId, isCustomisePage, dispatch, title, FaviconUrl]);

  const titleFontSize = `${Math.min(
    windowWidth * 0.06,
    windowHeight * 0.05,
  )}px`; // 30% of screen width and height
  const descriptionFontSize = `${Math.min(
    windowWidth * 0.04,
    windowHeight * 0.03,
  )}px`; // 20% of screen width and height

  if (loader) {
    return (
      <div
        style={{ backgroundColor: backgroundColor || secondaryColor }}
        className="fixed inset-0 flex items-center justify-center"
      >
        <ClipLoader
          color={secondaryColor}
          loading={loader}
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }

  return !isCustomisePage &&
    !preview &&
    (message === "Event yet to start" || message === "Event link expired") ? (
    <LinkActiveOrExpiredPage
      message={
        status === 200
          ? "This link is not active yet"
          : status === 400
            ? "This link is no longer active."
            : "The page you were looking for doesn't exist."
      }
    />
  ) : (
    <div
      ref={HomeRef}
      style={{
        backgroundImage:
          backgroundIMG !== "default" && backgroundIMG !== "none"
            ? `url(${REACT_APP_S3_URL}/spin-the-wheel/background/${backgroundIMG})`
            : backgroundIMG !== "none"
              ? `url(${sampleImg})`
              : "none",
        backgroundSize: "cover",
        backgroundColor: backgroundColor,
        backgroundRepeat: "no-repeat",
      }}
      className={`Home relative flex flex-col items-center justify-center ${
        !isCustomisePage || preview
          ? "m-0 h-screen w-screen rounded-none border-0 p-0"
          : "h-[100vh] w-full rounded-xl xl:h-full"
      } border border-secondary`}
    >
      {instruction && (
        <button
          className="absolute right-4 top-4 rounded-full bg-white bg-opacity-80 p-2 text-gray-800 hover:bg-opacity-60"
          onClick={() => setShowInstructions(true)}
          data-tooltip-id="instructions-tooltip"
          data-tooltip-content="Rules"
        >
          <List size={20} />
        </button>
      )}
      {instructions.length > 0 && showInstructions && (
        <div
          style={{ fontFamily: fontFamily }}
          className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50"
        >
          <div
            style={{
              backgroundColor: secondaryColor,
              WebkitTextFillColor: textColor,
            }}
            className="relative w-[50%] max-w-md rounded-lg bg-opacity-95 p-6"
          >
            <button
              className="absolute right-3 top-3 rounded-full bg-transparent bg-opacity-80 p-1 text-gray-800 hover:bg-opacity-60"
              onClick={() => setShowInstructions(false)}
              data-tooltip-id="close-tooltip"
              data-tooltip-content="Close"
            >
              <X size={16} color={textColor} />
            </button>
            <h2 className="my-4 text-center text-xl font-bold">Instructions</h2>
            <ul className="list-disc pl-5 text-left">
              {instructions.map((instruction, index) => (
                <li
                  style={{ color: textColor }}
                  key={index}
                  className="mb-2 break-words"
                >
                  {instruction.text}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}

      <div
        style={{
          backgroundColor:
            backgroundIMG === "default" || backgroundIMG !== "none"
              ? `rgba(${parseInt(secondaryColor.slice(1, 3), 16)}, ${parseInt(secondaryColor.slice(3, 5), 16)}, ${parseInt(secondaryColor.slice(5, 7), 16)}, 0.7)`
              : "transparent",
        }}
        className={`flex flex-col items-center justify-center rounded-md p-3 sm:p-7`}
      >
        {logo && (
          <div className="mb-4 flex items-center justify-center sm:mb-6">
            {LogoUrl ? (
              <a href={LogoUrl} target="_blank" rel="noopener noreferrer">
                <img
                  loading="lazy"
                  src={`${REACT_APP_S3_URL}/spin-the-wheel/logo/${logo}`}
                  alt="Logo"
                  style={{
                    width: `${logoSize.width}px`,
                    height: `${logoSize.height}px`,
                  }}
                />
              </a>
            ) : (
              <img
                src={`${REACT_APP_S3_URL}/spin-the-wheel/logo/${logo}`}
                alt="Logo"
                style={{
                  width: `${logoSize.width}px`,
                  height: `${logoSize.height}px`,
                }}
              />
            )}
          </div>
        )}

        <h1
          rel="preload"
          style={{
            fontFamily: fontFamily,
            WebkitTextFillColor: textColor,
            fontSize: titleFontSize,
          }}
          className={`mb-2 text-center font-bold sm:mb-4`}
        >
          {title}
        </h1>
        <p
          rel="preload"
          style={{
            fontFamily: fontFamily,
            WebkitTextFillColor: textColor,
          }}
          className={`w-56 text-center font-semibold xsm:w-64 sm:w-72 md:w-80`}
        >
          {description}
        </p>
        {!inAppParams && (
          <div
            rel="preload"
            style={{
              fontFamily: fontFamily,
            }}
            className="mt-6 w-56 space-y-4 xsm:w-64 sm:w-72"
          >
            <input
              onChange={(e) => {
                dispatch(
                  setUser({
                    ...user,
                    name: e.target.value,
                  }),
                );
              }}
              value={user.name}
              type="text"
              placeholder="Enter your name"
              className="h-10 w-full rounded-full border p-3"
            />
            <input
              onChange={(e) => {
                dispatch(
                  setUser({
                    ...user,
                    email: e.target.value,
                  }),
                );
              }}
              value={user.email}
              name="email"
              id="email"
              type="email"
              placeholder="Enter your email"
              className="h-10 w-full rounded-full border p-3"
            />
          </div>
        )}

        <div className="mt-6 flex flex-col items-center justify-center gap-y-4">
          <button
            disabled={isCustomisePage && !preview}
            style={{
              backgroundColor:
                backgroundIMG === "default" || backgroundIMG !== "none"
                  ? backgroundColor
                  : secondaryColor,
              WebkitTextFillColor: textColor,
              fontFamily: fontFamily,
            }}
            className={`w-28 rounded-full bg-black p-2 text-lg font-bold text-black shadow-md`}
            onClick={() => {
              handlePlayButtonClick();
            }}
          >
            PLAY
          </button>

          {preview && isCustomisePage && (
            <button
              data-tooltip-id="preview"
              data-tooltip-content="Preview"
              onClick={() => {
                dispatch(setPreview(!preview));
                navigate("/", { replace: true });
              }}
              className="absolute bottom-14 rounded-full bg-black px-4 py-2 text-center text-white hover:scale-105 hover:opacity-80"
            >
              {!preview ? "Preview" : "Exit preview"}
            </button>
          )}
        </div>
      </div>
      <BuiltWithWorkplay />
      <Tooltip
        id="instructions-tooltip"
        place="bottom"
        noArrow
        offset={10}
        delayShow={100}
        delayHide={100}
        style={{ borderRadius: "10px" }}
      />
      <Tooltip
        id="close-tooltip"
        place="bottom"
        noArrow
        offset={10}
        delayShow={100}
        delayHide={100}
        style={{ borderRadius: "10px" }}
      />
      {!isCustomisePage && <Toaster />}
      {!preview && isCustomisePage && (
        <button
          data-tooltip-id="start screen"
          onClick={() => dispatch(setCurrentScreen(0))}
          className={`absolute left-6 top-1/2 flex flex-row justify-between rounded-full bg-white p-2 ${
            currentScreen === 0 ? "cursor-not-allowed opacity-50" : ""
          }`}
        >
          <ChevronLeft size={20} />
        </button>
      )}
      {!preview && isCustomisePage && (
        <button
          data-tooltip-id="game screen"
          onClick={() => dispatch(setCurrentScreen(1))}
          className={`absolute right-6 top-1/2 flex flex-row justify-between rounded-full bg-white p-2 ${
            currentScreen === 1 ? "cursor-not-allowed opacity-50" : ""
          }`}
        >
          <ChevronRight size={20} />
        </button>
      )}
      {currentScreen !== 0 && isCustomisePage && (
        <Tooltip
          id="start screen"
          content="Play screen"
          place="bottom"
          noArrow
          offset={10}
          delayHide={500}
          style={{ borderRadius: "10px" }}
        ></Tooltip>
      )}
      {currentScreen !== 1 && isCustomisePage && (
        <Tooltip
          id="game screen"
          content="Game screen"
          place="bottom"
          noArrow
          offset={0}
          delayHide={0}
          style={{ borderRadius: "10px" }}
        ></Tooltip>
      )}
    </div>
  );
};

export default Home;
