import { Switch } from "@headlessui/react";
import HelpIconTooltipIcon from "./HelpIconTooltipIcon";

interface ToggleSwitchProps {
  checked: boolean;
  onChange: () => void;
  label: string;
  tooltip?: string;
  tooltipId?: string;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  checked,
  onChange,
  label,
  tooltip,
  tooltipId,
}) => (
  <div className="mb-4 flex items-center justify-between">
    <div className="flex items-center space-x-2">
      <label className="block text-base font-semibold">{label}</label>
      {tooltip && tooltipId && (
        <HelpIconTooltipIcon content={tooltip} tooltipId={tooltipId} />
      )}
    </div>
    <Switch
      checked={checked}
      onChange={onChange}
      className={`${checked ? "bg-blue-500" : "bg-gray-300"} relative inline-flex h-6 w-11 items-center rounded-full`}
    >
      <span className="sr-only">{label}</span>
      <span
        className={`${
          checked ? "translate-x-6" : "translate-x-1"
        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
      />
    </Switch>
  </div>
);

export default ToggleSwitch;
