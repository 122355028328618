import axios from "axios";

const { REACT_APP_API_URL } = process.env;

export const uploadLogoImage = async (file: File): Promise<string | null> => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const uploadImageUrl = `${REACT_APP_API_URL}/game/spin-the-wheel/upload-logo-image`;
    const response = await axios.put(uploadImageUrl, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    console.log("Logo image uploaded successfully.");
    const responseData =
      typeof response.data === "string"
        ? JSON.parse(response.data)
        : response.data;

    if (responseData.data?.fileName) {
      return responseData.data.fileName;
    } else {
      console.log("No fileName found in response data:", responseData);
      return null;
    }
  } catch (error) {
    console.error("Error uploading logo image:", error);
    return null;
  }
};
