const { REACT_APP_S3_URL } = process.env;
const pageNotFoundImg = `${REACT_APP_S3_URL}/assets/page-not-found.jpg`;

const PageNotFoundPage = () => {
  return (
    <div className="flex min-h-screen w-full items-center justify-center p-8">
      <div>
        <img
          className="m-auto h-[220px] sm:h-[400px]"
          src={pageNotFoundImg}
          alt="work in progress"
        />
        <h1 className="text-lg font-medium tracking-wider lg:text-xl">
          Oops! Page not Found
        </h1>
        <p className="m-auto mt-5 w-[260px] text-sm font-medium text-gray-600 sm:w-full sm:text-[15px] lg:w-full lg:text-base">
          The page you were looking for doesn't exist
        </p>
      </div>
    </div>
  );
};

export default PageNotFoundPage;
