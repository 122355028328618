import React from "react";
import { Tooltip } from "react-tooltip";

interface HelpIconTooltipIconProps {
  content: string;
  tooltipId: string;
}

const HelpIconTooltipIcon: React.FC<HelpIconTooltipIconProps> = ({
  content,
  tooltipId,
}) => {
  return (
    <div className="z-50 cursor-pointer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        preserveAspectRatio="xMidYMid meet"
        width="1.1rem"
        height="1.1rem"
        aria-label="Description"
        viewBox="0 0 16 16"
        data-tooltip-id={tooltipId}
        data-tooltip-content={content}
      >
        <g fill="#2C2C2D" fillRule="evenodd" clipRule="evenodd">
          <path d="M8 2.25C4.82436 2.25 2.25 4.82436 2.25 8C2.25 11.1756 4.82436 13.75 8 13.75C11.1756 13.75 13.75 11.1756 13.75 8C13.75 4.82436 11.1756 2.25 8 2.25ZM1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8ZM8.09865 5.375C7.4083 5.375 6.84865 5.93464 6.84865 6.625V6.76923H5.59865V6.625C5.59865 5.24429 6.71794 4.125 8.09865 4.125H8.23965C9.54249 4.125 10.5987 5.18116 10.5987 6.484C10.5987 7.16677 10.3028 7.81605 9.78763 8.26408L9.17194 8.79949C8.82447 9.10165 8.62497 9.53953 8.62497 10H7.37497C7.37497 9.17775 7.73122 8.39582 8.35169 7.85626L8.96738 7.32084C9.20959 7.11022 9.34865 6.80498 9.34865 6.484C9.34865 5.87152 8.85214 5.375 8.23965 5.375H8.09865ZM7.375 12V11.125H8.625V12H7.375Z"></path>
        </g>
      </svg>
      <Tooltip
        id={tooltipId}
        place="bottom"
        style={{
          borderRadius: "10px",
          padding: "10px 15px",
          maxWidth: "250px",
        }}
      />
    </div>
  );
};

export default HelpIconTooltipIcon;
