import axios from "axios";

interface ApiResponse {
  success: boolean;
  message?: string;
}

export const checkUserSession = async (
  gameId: string,
  userEmail: string | null,
  uid?: string,
  extraParams?: Record<string, any>,
) => {
  const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

  const requestBody = {
    spinTheWheelId: gameId,
    ...(userEmail
      ? { email: userEmail }
      : {
          fullName: "default",
          email: "default",
          inAppParams: { uid, ...extraParams },
        }),
  };

  try {
    const response: ApiResponse = await axios.post(
      `${REACT_APP_API_URL}/game/spin-the-wheel/check-user`,
      requestBody,
    );

    return response;
  } catch (error: any) {
    throw error;
  }
};
